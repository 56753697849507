<template>
  <div>
    <FormulaBanner :title="title" :formulaCode="formulaCode"/>
    <div class="index">
      <!-- 表单 -->
      <div>
        <div class="top_nav"><span></span>{{ title }}</div>
        <div class="formBody">
          <!-- 表单 -->
          <van-form @submit="onSubmit">
            <MyPatient :memberId="memberId" :selectValue="selectValue" @change="userChange"/>
            <van-field
                v-model="xueqingna"
                :rules="[{ required: true, message: '请填写' },{ pattern: /^[+-]?(0|([1-9]\d*))(\.\d+)?$/, message: '请填写数字'}]"
            >
              <template #input>
                <div class="titles">
                  <span class="titlesName">血清钠 <i>*</i></span>
                  <span style="display: flex;align-items: center;">
                     <span class="information">
                       <van-cell-group class="vanCell">
                         <van-field v-model="xueqingna" placeholder="请输入" />
                       </van-cell-group>
                     </span>
                     <div class="unit"> <span>mmol/L</span></div>
                   </span>
                </div>
              </template>
            </van-field>
            <van-field
                v-model="niaona"
                :rules="[{ required: true, message: '请填写' },{ pattern: /^[+-]?(0|([1-9]\d*))(\.\d+)?$/, message: '请填写数字'}]"
            >
              <template #input>
                <div class="titles">
                  <span class="titlesName">尿钠 <i>*</i></span>
                  <span style="display: flex;">
                     <span class="information">
                       <van-cell-group class="vanCell">
                         <van-field v-model="niaona" placeholder="请输入" />
                       </van-cell-group>
                     </span>
                     <div class="unit"><span>mmol/L</span></div>
                   </span>
                </div>
              </template>
            </van-field>
            <van-field
                v-model="xueqingjigan"
                :rules="[{ required: true, message: '请填写' },{ pattern: /^[+-]?(0|([1-9]\d*))(\.\d+)?$/, message: '请填写数字'}]"
            >
              <template #input>
                <div class="titles">
                  <span class="titlesName">血清肌酐 <i>*</i></span>
                  <span style="display: flex;">
                     <span class="information">
                       <van-cell-group class="vanCell">
                         <van-field v-model="xueqingjigan" placeholder="请输入" />
                       </van-cell-group>
                     </span>
                     <div class="unit"><span>μmol/L</span></div>
                   </span>
                </div>
              </template>
            </van-field>
            <van-field
                v-model="niaojigan"
                :rules="[{ required: true, message: '请填写' },{ pattern: /^[+-]?(0|([1-9]\d*))(\.\d+)?$/, message: '请填写数字'}]"
            >
              <template #input>
                <div class="titles">
                  <span class="titlesName">尿肌酐<i>*</i></span>
                  <span style="display: flex;">
                     <span class="information">
                       <van-cell-group class="vanCell">
                         <van-field v-model="niaojigan" placeholder="请输入" />
                       </van-cell-group>
                     </span>
                     <div class="unit"><span>μmol/L</span></div>
                   </span>
                </div>
              </template>
            </van-field>
            <div v-if="compute === true" style="margin: 16px;display: flex;justify-content: center;">
              <van-button class="formula-template-button"  block type="info" native-type="submit" style="width:90px">提交</van-button>
            </div>
          </van-form>
        </div>
      </div>
      <CalculationResults
          v-if="show"
          :countText="countValue"
          :countTextList="[]"
      ></CalculationResults>
      <!-- 后台设置的值 -->
      <FormulaExplain :explain-data="StandardDescription"></FormulaExplain>
      <!-- 参考文献 -->
      <FormulaReferences :references-data="references"></FormulaReferences>
    </div>
  </div>

</template>

<script>
import { getFormula ,getFormulaHuiXian ,postFormula} from "@/service/BodyMassIndex_api";
import MyPatient from '@/components/MyPatient.vue'
import FormulaBanner from '@/components/FormulaBanner.vue'
import router from "@/router";
import {Toast} from "vant";
import FormulaExplain from '@/components/template1/FormulaExplain'
import FormulaReferences from '../../../components/template1/FormulaReferences.vue';
import CalculationResults from "@/components/CalculationResults.vue";
// import { result } from "lodash";
export default {
  name: "medicalSearch",
  data(){
    return{
      compute:true,
      show:false,
      //   searchValue:null,
      countValue:'',
      formulaCode:'',
      xueqingna:'',
      niaona:'',
      xueqingjigan:'',
      niaojigan:'',
      napaixie:'',
      Submitted:false,
      columnCode:'',
      formulaId:'',
      title:"",
      memberId: '1111',
      patientId:null,
      selectValue: '',
      showPopover: false,
      StandardDescription:[],
      FormulaResult:"",
      references:[],
      result:{},
    }
  },
  components: {
    CalculationResults,
    FormulaBanner,
    MyPatient,
    FormulaReferences,
    FormulaExplain
  },
  async created(){
    await this.getTheFormula()
    await this.showRecord()
  },
  watch:{
    "xueqingna":{
      immediate:true,
      handler:function(){
        this.show = false;
      }
    },
    "niaona":{
      immediate:true,
      handler:function(){
        this.show = false;
      }
    },
    "xueqingjigan":{
      immediate:true,
      handler:function(){
        this.show = false;
      }
    },
    "niaojigan":{
      immediate:true,
      handler:function(){
        this.show = false;
      }
    },
  },
  methods:{
    // 回显数据
    async showRecord(){
      if(this.$router.currentRoute.query.location === 'YXGS_RECORD_LIST' && this.$router.currentRoute.query.logId){
        // this.compute = false
        const data = {
          id:this.$router.currentRoute.query.logId
        }
        const response = await getFormulaHuiXian(data)
        if (response.code === 0) {
          // console.log("回显数据",response.data)
          this.xueqingna = response.data.content.xueqingna.value
          this.niaona = response.data.content.niaona.value
          this.xueqingjigan = response.data.content.xueqingjigan.value
          this.niaojigan = response.data.content.niaojigan.value

          if (response.data.patientId) this.selectValue = {id: response.data.patientId, name: response.data.patientName};this.patientId = response.data.patientId
          // 计算结果
          this.Submitted = true
          this.countValue=response.data.result.napaixie.value
          // console.log("id",this.memberId)
          this.$nextTick(() => {
            this.show=true
          })
        } else {
          Toast.fail(response.message)
        }
      }
    },
    // 获取公式
    async getTheFormula(){
      // console.log("路由",router)
      var data = router.currentRoute.path.substring(9,router.currentRoute.path.Length)
      const response = await getFormula(this.$router.currentRoute.query.channel,data)
      if (response.code === 0) {
        this.StandardDescription = response.data.explain // 存储标准说明
        this.references = response.data.references // 参考文献
        this.title = response.data.chineseName //公式名称
        this.formulaId = response.data.id
        this.columnCode = response.data.columnCode
        this.formulaCode = response.data.code
        // console.log("公式数据",response.data)
      } else {
        Toast.fail(response.message)
      }
    },
    applyPrecision(result) {
      // 转换为浮点数
      const floatValue = parseFloat(result);
      // 如果是浮点数且小数位数超过四位则保留四位小数，否则返回原始结果
      if (!isNaN(floatValue) && floatValue % 1 !== 0) { // 检查是否有小数
        const roundedValue = floatValue.toFixed(2); // 四舍五入保留四位小数
        return parseFloat(roundedValue).toString(); // 转回字符串
      }
      return floatValue.toString(); // 返回原始结果字符串
    },
    async onSubmit(){
      this.countValue= this.applyPrecision((this.niaona*this.xueqingjigan) / (this.xueqingna * this.niaojigan) * 100) +'%'
      this.Submitted = true
      var data = {}
      if(this.$router.currentRoute.query.location === 'YXGS_RECORD_LIST'){
        data = {
          columnCode:this.columnCode,
          patientId:this.patientId,
          formulaId:this.formulaId,
          formulaCode:this.formulaCode,
          content:{
            xueqingna:{value:this.xueqingna , unit:"mmol/L"},
            niaona:{value:this.niaona , unit:"mmol/L"},
            xueqingjigan:{value:this.xueqingjigan , unit:"μmol/L"},
            niaojigan:{value:this.niaojigan , unit:"μmol/L"},
          },
          result:{
            napaixie:{value:this.countValue,unit:""},
          },
          channel:this.$route.query.channel,
          location:this.$route.query.location,
          id:this.$router.currentRoute.query.logId,
        }
      }else{
        data = {
          columnCode:this.columnCode,
          patientId:this.patientId,
          formulaId:this.formulaId,
          formulaCode:this.formulaCode,
          content:{
            xueqingna:{value:this.xueqingna , unit:"mmol/L"},
            niaona:{value:this.niaona , unit:"mmol/L"},
            xueqingjigan:{value:this.xueqingjigan , unit:"μmol/L"},
            niaojigan:{value:this.niaojigan , unit:"μmol/L"},
          },
          result:{
            napaixie:{value:this.countValue,unit:""},
            DisplayResults:this.countValue
          },
          channel:this.$route.query.channel,
          location:this.$route.query.location,
        }
      }
      this.show=true
      const response = await postFormula(data)
      if (response.code === 0) {
        Toast.success('提交成功')
      } else {
        Toast.fail(response.message)
      }
    },
    userChange (data) {
      this.selectValue = data
      // console.log("全部数据",this.selectValue)
      this.patientId = this.selectValue.id
      this.patientSex = this.selectValue.sex
    },
    // 开参考文献链接
    openLink(u){
      //获取系统版本
      let userAI = navigator.userAgent;
      const isAndroid = userAI.indexOf('Android') > -1 || userAI.indexOf('Linux') > -1; //Android终端
      const isIOS = !!userAI.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
      if (isAndroid) {
        // alert('安卓操作系统')
        // 这个是安卓操作系统
        window.open(u)
      }
      if (isIOS) {
        // 这个是ios操作系统
        // alert('ios操作系统')
        window.location.href = u
      }
    }
  }
}
</script>


<style scoped lang="scss">
.index{
  background: #F6F6F6;
  padding: 10px 15px;
  height: 100vh;
  // overflow:scroll ;
  .StandardDescription{
    margin-top:10px;
    .top_nav{
      font-size: 16px;
      color: #333333;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: start;
      margin-bottom:10px;
      span {
        display: inline-block;
        width: 5px;
        height: 15px;
        background: #259beb;
        margin-right: 5px;
      }
    }
    .StandardDeatil{
      width:100%;
      padding: 15px 10px;
      //   height: 50px;
      background-color: #fff;
      box-sizing: border-box;
      .references{
        margin-bottom:15px
      }
    }
    .StandardDeatil2{
      width:100%;
      padding: 15px 10px;
      padding-bottom:1px;
      //   height: 50px;
      background-color: #fff;
      box-sizing: border-box;
      .references{
        margin-bottom:15px
      }
    }
  }
  .top_nav{
    font-size: 16px;
    color: #333333;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom:10px;
    span {
      display: inline-block;
      width: 5px;
      height: 15px;
      background: #259beb;
      margin-right: 5px;
    }
  }
  .formBody{
    background-color: #fff;
    padding:0px 0px 10px 0px;
    .CalculationResultsBox{
      padding:0 8px;
      .CalculationResults{
        font-size: 16px;
        color: #333333;
        font-weight: bold;
        display: flex;
        justify-content: center;
        margin-bottom:10px
      }
      .CalculationDetail{
        font-size: 15px;
        color: #333333;
        // font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: start;
        margin-bottom:10px;
        background-color: #f6f6f6;
        // height: 16px;
        span{
          display: inline-block;
          width: 3px;
          height: 24px;
          background: #fe9712;
          margin-right: 5px;
        }
      }
    }
  }
  .titles{
    display:flex;
    align-items: center;
    justify-content: space-between;
    width:100%;
    height: 45px;
    border-bottom: 1px solid #f0f0f0;
    padding: 0 10px;
    box-sizing: border-box;
    .titlesName{
      font-size:16px;
      i{
        color: red;
      }
    }
    .information{
      font-size:16px;
      margin-right:4px;
      .PleaseSelect{
        color: #b9b9b9;
      }
      .vanCell{
        width:50px;
        padding:0;
        height: 100%;
      }
      .vanCell1{
        width:125px;
        padding:0;
        height: 100%;
      }
    }
    .vBotton{
      width:60px
    }
    .unit{
      height: 45px;
      //   line-height: 45px;
      display: flex;
      align-items: center;
      font-size:16px;
      // width:20px;
    }
    .vanIcon{
      font-size:13px;
      color:#a8a8a8;
      font-weight: 650;
    }
  }
}
::v-deep .van-cell {
  padding:0px 0px;
}
::v-deep .van-field__control{
  height: 45px;
  font-size: 16px;
}
::v-deep .van-field__error-message {
  position: absolute;
  top: 25px;
  left: 10px;
}
::v-deep p {
  padding: 0;
  margin: 0;
}
</style>
